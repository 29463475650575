import { useState } from "react";
import "./styles.css";

function QuantityCounter(props) {
  const { count, incrementCount, decrementCount, deleteItem } = props;
  return (
    <div className="QuantityCounter">
      <button onClick={incrementCount}>+</button>
      <div>{count}</div>
      <button onClick={decrementCount}>
        -{/* {count < 2 ? "DELete" : "-"} */}
      </button>
      <button onClick={deleteItem}>del</button>
    </div>
  );
}

function AddIngredient({ addIngredientToList }) {
  const [item, setItem] = useState("");
  function itemInput(event) {
    const value = event.target.value;
    setItem(value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    // if no item to add, do nothing, return
    if (!item) {
      return;
    }
    addIngredientToList({
      title: item,
      count: 1,
    });
    setItem("");
  }

  return (
    <div className="AddIngredient">
      <form onSubmit={handleSubmit}>
        <input
          value={item}
          onChange={itemInput}
          type="text"
          placeholder="enter item..."
        />
        <input type="submit" value="submit" />
      </form>
    </div>
  );
}

function IngContainer({ itemsToList, deleteItem, updateItemCount }) {
  function incrementCount(item) {
    updateItemCount(item, item.count + 1);
  }
  function decrementCount(item) {
    if (item.count <= 1) {
      deleteItem(item);
    } else {
      updateItemCount(item, item.count - 1);
    }
  }

  return (
    <div className="IngContainer">
      {itemsToList.map((item, i) => {
        return (
          <div key={i} className="ShopListItem">
            <h1>
              item {i + 1}:{item.title}
            </h1>

            <QuantityCounter
              count={item.count}
              incrementCount={() => incrementCount(item)}
              decrementCount={() => decrementCount(item)}
              deleteItem={() => deleteItem(item)}
            />
          </div>
        );
      })}
    </div>
  );
}

function ShopListContainer() {
  const [items, setItems] = useState([]);
  function addItem(item) {
    const newList = [...items, item];
    setItems(newList);
  }
  function deleteItem(item) {
    const newList = items.filter((currItem) => currItem.title !== item.title);
    setItems(newList);
  }
  function updateItemCount(item, newCount) {
    const newList = items.filter((currItem) => currItem.title !== item.title);
    item.count = newCount;
    newList.push(item);
    setItems(newList);
  }

  return (
    <div className="ShopListContainer">
      <h3>Shopping List</h3>
      <AddIngredient addIngredientToList={addItem} />
      <IngContainer
        itemsToList={items}
        deleteItem={deleteItem}
        updateItemCount={updateItemCount}
      />
    </div>
  );
}

export default ShopListContainer;
